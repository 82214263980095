/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-svg': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2v-1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM0 14.841a1.13 1.13 0 00.401.823q.194.162.478.252.285.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 00.187-.656q0-.336-.135-.56a1 1 0 00-.375-.357 2 2 0 00-.565-.21l-.621-.144a1 1 0 01-.405-.176.37.37 0 01-.143-.299q0-.234.184-.384.187-.152.513-.152.214 0 .37.068a.6.6 0 01.245.181.56.56 0 01.12.258h.75a1.1 1.1 0 00-.199-.566 1.2 1.2 0 00-.5-.41 1.8 1.8 0 00-.78-.152q-.44 0-.776.15-.337.149-.528.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 01.153.326.5.5 0 01-.085.29.56.56 0 01-.256.193q-.167.07-.413.07-.176 0-.32-.04a.8.8 0 01-.248-.115.58.58 0 01-.255-.384zm4.575 1.09h.952l1.327-3.999h-.879l-.887 3.138H5.05l-.897-3.138h-.917zm5.483-3.293q.114.228.14.492h-.776a.8.8 0 00-.096-.249.7.7 0 00-.17-.19.7.7 0 00-.237-.126 1 1 0 00-.3-.044q-.427 0-.664.302-.235.3-.235.85v.497q0 .352.097.616a.9.9 0 00.305.413.87.87 0 00.518.146 1 1 0 00.457-.097.67.67 0 00.273-.263q.09-.164.09-.364v-.254h-.823v-.59h1.576v.798q0 .29-.096.55a1.3 1.3 0 01-.293.457 1.4 1.4 0 01-.495.314q-.296.111-.698.111a2 2 0 01-.752-.132 1.45 1.45 0 01-.534-.377 1.6 1.6 0 01-.319-.58 2.5 2.5 0 01-.105-.745v-.507q0-.54.199-.949.202-.406.583-.633.383-.228.926-.228.357 0 .635.1.282.1.48.275.2.176.314.407"/>',
    },
});
